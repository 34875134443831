.projectSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-lg);
  flex-direction: column;
}

.projectDetailsContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: fit-content;
  gap: var(--gap-lg);
}

.projectDetailsContent {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: var(--gap-md);
}

.mobileScreenshotContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileScreenshotContent {
  width: 100%;
  height: auto;
  max-height: 500px;
  overflow: hidden;
}

.projectLinksContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-md);
}

.projectLink {
  margin: 0;
}

.desktopScreenshotContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktopScreenshotContent {
  width: 100%;
  height: auto;
  max-width: var(--content-max-width);
}

.screenshotPreviewsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-sm);
}

.screenshotPreviewsContainer button {
  background-color: transparent;
  border: none;
}

.screenshotPreviewContent {
  height: 100px;
  width: auto;
}

@media (hover: hover) {
  .screenshotPreviewsContainer button:hover {
    cursor: pointer;
  }
}

@media (max-width: 700px) {
  .projectDetailsContainer {
    flex-direction: column;
    align-items: center;
  }

  .projectDetailsContent {
    width: 100%;
  }
  .screenshotPreviewContent {
    height: 70px;
  }
}

@media (max-width: 480px) {
  .projectLinksContainer {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .mobileScreenshotContent {
    max-height: fit-content;
  }

  .mobileScreenshotContainer {
    padding: 0 var(--padding-sm);
  }

  .desktopScreenshotContainer {
    padding: 0 var(--padding-sm);
  }
  .screenshotPreviewContent {
    height: 50px;
  }
}
